import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <h4>{`A Software Engineer with an American & European Background`}</h4>
    <p>{`For almost three years, I've been contributing to solving problems in the American healthcare industry. I've been very fortunate to participate in software endeavours related to Human Genomics, and improving the quality of patient care using artificial intelligence and cloud computing. This work experience has exposed me to ETL design and construction and automating physician workflows by harnessing the power of big data, analytics and cloud AI platforms. That said, I'm very comfortable working in other industries given that I'm more passionate about the art of software engineering over anything else.`}</p>
    <h4>{`Technical Expertise`}</h4>
    <p>{`My most recent work has revolved around setting back-end infrastructure and monitoring tools to assess device performance located in hospitals. As a result, this has exposed me to microservices development, orchestration, cloud computing configuration and interfacing with cloud service APIs while also designing and constructing our CI/CD pipelines to address our very rapid development cycles. The start-up environment has also granted me the opportunity of learning and integrating real-time computer vision applications using libraries like OpenCV, TensorFlow and Keras.`}</p>
    <p>{`In my free time, I've also developed applications that have tackled problems in Finance, Music, Self-driving and Text Mining.`}</p>
    <p>{`My current tech stack is predominantly using a mixture of Java, C++, Python and most recently Go while also leveraging cloud service platforms like Azure and GCP to interface with cloud AI APIs and serve micrcoservices (via Google Kubernetes Engine) respectively. `}</p>
    <p>{`I've used frameworks like Spring Boot, Flask and Gin for microservice development, ML libraries likes Tensorflow, Keras, Scikit Learn exclusively for prototyping, and monitoring and C++ for embedded software development on miniature low-powered systems. `}</p>
    <h4>{`Hobbies`}</h4>
    <p>{`In my free time, I like playing the violin, listening to 60s music and doing amateur photography.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      